
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src

@Component({
    components: {
        Header,
    },
})
export default class AccessDenied extends Vue {

}
